import { alpha, createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export const appTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00857F",
      shade: "#2FAC66",
      light: "#89cfdf52",
    },
    secondary: {
      main: "#CCE7E5",
    },
    red: {
      main: "#E50046",
      contrastText: "#FFFFFF",
    },
    orange: {
      main: "#EC6608",
      contrastText: "#FFFFFF",
      light: "#F5A254",
    },
    purple: {
      main: "#524C9A",
    },
    blue: {
      main: "#007BFF",
      contrastText: "#FFFFFF",
    },
    yellow: {
      main: "#fbba00",
    },
    tealishBlue: {
      main: "#3F465B",
    },
    buntingBlack: {
      main: "#1F114D",
    },
    LightSilver: {
      main: "#cfd8dc",
      contrastText: "#1f114d",
      hover: "#DDE4E7",
    },
    error: {
      main: "#d32f2f",
    },
  },
  typography: {
    fontFamily: "Conduits",
    fontSize: 16,
    button: {
      textTransform: "initial",
    },
    color: "#1F114D",
    h3: {
      fontSize: 24,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          "&:last-child": {
            paddingBottom: 5,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          lineHeight: "inherit",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "0.25rem",
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#007BFF",
            },
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#007BFF",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: "0.25rem",
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#007BFF",
            },
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#007BFF",
          },
          "& .MuiChip-root": {
            color: "#007BFF",
            backgroundColor: "#e7f4ff",
            lineHeight: "1rem",
            margin: "1px 3px 2px 0px",
            borderRadius: "0.25rem",
            "& > .MuiSvgIcon-root": {
              color: "#007BFF",
              opacity: 0.5,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 58,
          height: 30,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(28px)",
              color: "#00857F",
              "& + .MuiSwitch-track": {
                opacity: 1,
                border: 0,
                backgroundColor: alpha("#3F465B", 0.1),
                "&:before": {
                  opacity: 1,
                },
                "&:after": {
                  opacity: 0,
                },
              },
              "& .MuiSwitch-thumb": {
                opacity: 1,
                border: 0,
                backgroundColor: "#00857F",
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: grey[100],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            backgroundColor: "#3F465B",
            boxSizing: "border-box",
            width: 26,
            height: 26,
          },
          "& .MuiSwitch-track": {
            opacity: 1,
            borderRadius: 30 / 2,
            backgroundColor: alpha("#3F465B", 0.1),
            // transition: create(["background-color"], { duration: 500 }),
            "&:before, &:after": {
              position: "absolute",
              top: "50%",
              transform: "translateY(-54%)",
              fontSize: 14,
              width: 16,
              height: 16,
            },
            "&:before": {
              left: 8,
              content: '"Yes"',
              color: "#00857F",
              opacity: 0,
            },
            "&:after": {
              right: 8,
              content: '"No"',
              color: "#3F465B",
              opacity: 1,
            },
          },
        },
      },
    },
  },
  shape: {
    // borderRadius: 4,
  },
});
