/** * @module actions */

import dayjs from "dayjs";

import { ACTION } from "utils/constants/action/action";
import { KANBAN_COLUMN_STATES } from "utils/constants/assignment/assignment";
import { EXECUTIVE_SEARCH_INTERVIEW_TYPE } from "utils/constants/assignment/executive-search";
import { POSITIVE_RESULT_ACTION_OPTIONS } from "utils/constants/result/result";

import { parseHtmlToString } from "./string";

/**
 * @typedef {Object} ActionTypes
 * @property {string} NEGATIVE_ANSWER_SENT - "Negative answer sent"
 * @property {string} LINCOLN_INTERVIEW - "Lincoln Interview"
 * @property {string} PHONE_CALL - "Phone call"
 * @property {string} JOB_OFFER_RECEIVED - "Job offer received"
 * @property {string} SHORT_LISTED - "Short-list sent"
 * @property {string} MESSAGE_EMAIL - "Message/E-mail"
 * @property {string} CANDIDATE_NOT_YET_CONTACTED - "Candidate not yet contacted"
 * @property {string} LONG_LIST - "Long-list"
 * @property {string} CANDIDATE_HIRED - "Candidate hired"
 * @property {string} FOLLOW_UP_ONBOARDING - "Follow up onboarding"
 * @property {string} REFERENCE_CHECK - "Reference check"
 * @property {string} CANDIDATE_REPORT_SEND - "Candidate's report sent"
 * @property {string} SEND_MCPH - "MCPH - Questionnaire sent"
 * @property {string} CLIENT_INTERVIEW - "Client interview"
 * @property {string} MCPH_DEBRIEFING - "MCPH Debriefing"
 * @property {string} SEND_TALE_ME_MORE - "Tale me more sent"
 * @property {string} TALE_ME_MORE_DEBRIEFING - "Tale me more debriefing"
 * @property {string} NO_ACTION - "No action yet"
 * @property {string} PHONE_SKYPE_INTERVIEW - "Phone/skype interview"
 * @property {string} SHORT_LISTED_RESULT - "Short-list"
 * @property {string} LINKEDIN_INVITATION - "Linkedin invitation"
 * @property {string} MDT_ASSIGNMENT_FOLLOW_UP_MEETING - "MDT assignement follow up meeting"
 * @property {string} MDT_CONFIDENTIAL_AGREEMENT - "MDT Confidential agreement"
 * @property {string} MDT_CONTRACT_SIGNED - "MDT contract signed"
 * @property {string} MDT_END_OF_ASSIGNMENT_REPORT - "MDT End of assignment report"
 * @property {string} MDT_FOLLOW_UP_MONTHLY_WORKING_DAYS - "MDT Follow up monthly working days"
 * @property {string} MDT_ROADMAP - "MDT Roadmap"
 */

/**
 * Returns the most recent action of a specified type from a list of actions.
 *
 * @param {Array} params.actions - The array of action objects.
 * @param {string} params.type - The action type to filter by.
 * @returns {Object} The most recent action of the specified type.
 *
 */
export function getLastActionByType({ actions = [], type = "" }) {
  const results = actions.filter((data) => type.includes(data.action_type));

  if (results.length === 0) return {};

  const lastAction = results?.reduce((prev, current) => {
    if (!prev?.datetime) return current;
    const prevDate = dayjs(prev.datetime);
    const currentDate = dayjs(current.datetime);
    return prevDate.isBefore(currentDate) ? current : prev;
  }, {});

  return lastAction;
}

/**
 * Filters actions by excluding those with IDs present in the filter array.
 *
 * @param {Array<number|string>} params.filter - - Array of action IDs to exclude.
 * @param {Array} params.actions -  The array of action objects.
 * @returns {Array<Object>} The filtered list of action objects.
 *
 */
export function filterActionById({ filter, actions }) {
  let res = [];
  res = actions?.filter((action) => {
    const found = filter.find((element) => {
      return element === action?.id;
    });
    return !found;
  });
  return res;
}

/**
 * Determines the appropriate assignment pipe column based on the given action.
 *
 * @returns {string} The assignment pipe column associated with the action type.
 *
 */
export function actionRules(action) {
  switch (action) {
    case ACTION.PHONE_CALL:
      return KANBAN_COLUMN_STATES.QUALIFIED;
    case ACTION.SHORT_LISTED:
      return KANBAN_COLUMN_STATES.SHORT_LISTED;
    case ACTION.PHONE_SKYPE_INTERVIEW:
      return KANBAN_COLUMN_STATES.LINCOLN_INTERVIEW;
    case ACTION.LINCOLN_INTERVIEW:
      return KANBAN_COLUMN_STATES.LINCOLN_INTERVIEW;
    case ACTION.JOB_OFFER_RECEIVED:
      return KANBAN_COLUMN_STATES.IN_OFFER;
    case ACTION.CLIENT_INTERVIEW:
      return KANBAN_COLUMN_STATES.CLIENT_INTERVIEW;
    case ACTION.CANDIDATE_HIRED:
      return KANBAN_COLUMN_STATES.RECRUITED;
    case ACTION.IDENTIFIED:
      return KANBAN_COLUMN_STATES.IDENTIFIED;
    case ACTION.REFERENCE_CHECK:
      return "current";
    case ACTION.MESSAGE_EMAIL:
      return "current";
    default:
      return "";
  }
}

/**
 * Determines the interview type based on the provided action name.
 *
 * @param {Object} params - The parameters for determining the interview type.
 * @param {string} params.actionName - The action name to determine the interview type.
 * @returns {string} The interview type, either face-to-face or video conference.
 * @example
 * const interviewType1 = getInterviewType({ actionName: ACTION.LINCOLN_INTERVIEW });
 * console.log(interviewType1); // Output: EXECUTIVE_SEARCH_INTERVIEW_TYPE.FACE_TO_FACE
 *
 * const interviewType2 = getInterviewType({ actionName: ACTION.PHONE_SKYPE_INTERVIEW });
 * console.log(interviewType2); // Output: EXECUTIVE_SEARCH_INTERVIEW_TYPE.VISIOCONFERENCE
 */
export function getInterviewType({ actionName = "" }) {
  return actionName === ACTION.LINCOLN_INTERVIEW
    ? EXECUTIVE_SEARCH_INTERVIEW_TYPE.FACE_TO_FACE
    : EXECUTIVE_SEARCH_INTERVIEW_TYPE.VISIOCONFERENCE;
}

/**
 * Determines the reverse kanban column state based on the given action.
 *
 * @param {string} action - The action to determine the reverse kanban column state.
 * @returns {string} The reverse kanban column state corresponding to the action.
 * @example
 * console.log(actionRulesReverse(ACTION.PHONE_CALL)); // Output: KANBAN_COLUMN_STATES.IDENTIFIED
 * console.log(actionRulesReverse(ACTION.SHORT_LISTED)); // Output: KANBAN_COLUMN_STATES.LINCOLN_INTERVIEW
 * console.log(actionRulesReverse(ACTION.CLIENT_INTERVIEW)); // Output: KANBAN_COLUMN_STATES.SHORT_LISTED
 */
export function actionRulesReverse(action) {
  switch (action) {
    case ACTION.PHONE_CALL:
      return KANBAN_COLUMN_STATES.IDENTIFIED;

    case ACTION.SHORT_LISTED:
      return KANBAN_COLUMN_STATES.LINCOLN_INTERVIEW;

    case ACTION.PHONE_SKYPE_INTERVIEW:
      return KANBAN_COLUMN_STATES.QUALIFIED;

    case ACTION.LINCOLN_INTERVIEW:
      return KANBAN_COLUMN_STATES.QUALIFIED;

    case ACTION.JOB_OFFER_RECEIVED:
      return KANBAN_COLUMN_STATES.CLIENT_INTERVIEW;

    case ACTION.CLIENT_INTERVIEW:
      return KANBAN_COLUMN_STATES.SHORT_LISTED;

    case ACTION.CANDIDATE_HIRED:
      return KANBAN_COLUMN_STATES.IN_OFFER;

    case ACTION.IDENTIFIED:
      return KANBAN_COLUMN_STATES.IDENTIFIED;

    case ACTION.REFERENCE_CHECK:
      return "current";

    case ACTION.MESSAGE_EMAIL:
      return "current";
    default:
      return "";
  }
}

/**
 * Filters actions by type and returns the action with the highest `ats_action_id`.
 *
 * @param {Object} params - The parameters object.
 * @param {Array<Object>} params.actions - The array of action objects.
 * @param {string} params.type - The action type to filter by.
 * @returns {Object} - The action object with the highest `ats_action_id` of the specified type, or an empty object if no actions match.
 */
export function getLastActionByTypeAndById({ actions = [], type = "" }) {
  const filterActions = actions.filter((action) => action.action_type === type);

  if (filterActions.length === 0) return {};

  return filterActions.reduce((acc, cur) =>
    acc?.ats_action_id > cur?.ats_action_id ? acc : cur,
  );
}

/**
 * Retrieves the last action based on the provided actions array, type, and optional key for search.
 *
 * @param {Object} params - The parameters for retrieving the last action.
 * @param {Array} params.actions - The array of actions to search through.
 * @param {string} params.type - The type of action to filter by.
 * @param {string} [params.keySearch=""] - The optional key to search for within the actions.
 * @returns {Object} The last action object found based on the criteria.
 * @example
 * const actions = [
 *   { action_type: ACTION.PHONE_CALL, datetime: '2024-07-03T12:00:00Z' },
 *   { action_type: ACTION.LINCOLN_INTERVIEW, datetime: '2024-07-02T10:00:00Z' },
 *   { action_type: ACTION.CLIENT_INTERVIEW, datetime: '2024-07-01T15:00:00Z' }
 * ];
 *
 * const lastAction1 = getLastActionByDateTime({ actions, type: ACTION.LINCOLN_INTERVIEW });
 * console.log(lastAction1); // Output: { action_type: ACTION.LINCOLN_INTERVIEW, datetime: '2024-07-02T10:00:00Z' }
 *
 * const lastAction2 = getLastActionByDateTime({ actions, type: ACTION.PHONE_CALL, keySearch: 'id' });
 * console.log(lastAction2); // Output: {}
 */
export function getLastActionByDateTime({
  actions = [],
  type = "",
  keySearch = "",
}) {
  const results = actions.filter((data) =>
    type.includes(data?.action_type.trim()),
  );

  if (results.length === 0) return {};

  // define the search depending on highest id in the array
  if (keySearch) {
    return results.reduce(
      (prev, current) => {
        return current[keySearch] > prev[keySearch] ? current : prev;
      },
      { [keySearch]: -1 },
    );
  }

  const lastAction = results.reduce((prev, current) => {
    if (!prev?.datetime) return current;
    const prevDate = dayjs(prev.datetime);
    const currentDate = dayjs(current.datetime);
    return prevDate.isBefore(currentDate) ? current : prev;
  }, {});

  return lastAction;
}

/**
 * Checks if a result string matches all elements in the POSITIVE_RESULT_ACTION_OPTIONS array.
 *
 * @param {string} [result=""] - The result string to check.
 * @returns {boolean} - True if the result string matches all positive options, false otherwise.
 *
 * @example
 * // Example usage
 * const result1 = 'Candidate declined';
 * const result2 = 'Client Offer declined';
 * const result3 = 'Job offer accepted';
 *
 * const isPositive1 = isResultPositive(result1);
 * console.log(isPositive1); // Output: true (assuming 'Job offer accepted' is in POSITIVE_RESULT_ACTION_OPTIONS)
 *
 * const isPositive2 = isResultPositive(result2);
 * console.log(isPositive2); // Output: true (assuming 'Client Offer declined' is not in POSITIVE_RESULT_ACTION_OPTIONS)
 *
 * const isPositive3 = isResultPositive(result3);
 * console.log(isPositive3); // Output: false (assuming 'Candidate declined' is not in POSITIVE_RESULT_ACTION_OPTIONS)
 */
export function isResultPositive(result = "") {
  return POSITIVE_RESULT_ACTION_OPTIONS.every(
    (current) => current.toUpperCase() === result.toUpperCase(),
  );
}

/**
 * Retrieves data from the last action based on type and optionally includes result.
 *
 * @param {Array} actions - The list of action objects to search.
 * @param {string} type - The type of action to find.
 * @param {boolean} [hasResult=false] - Whether to include the result in the returned data.
 * @returns {{ comment: string, result: string }} - Object containing comment and optionally result from the last action.
 *
 * @example
 * // Example usage with mocked data
 * const actions = [
 *   { action_type: 'send_tale_me_more', comment: '<p>Successfully sent Tale Me More</p>', result_type: 'Success' },
 *   { action_type: 'send_mcph', comment: '<p>Failed to send MCPH</p>', result_type: 'Failure' },
 * ];
 *
 * const type = 'send_tale_me_more'; // Type of action to find
 *
 * // Get last action data without result
 * const dataWithoutResult = getLastActionData(actions, type);
 * console.log(dataWithoutResult);
 * // Output example: { comment: 'Successfully sent Tale Me More' }
 *
 * // Get last action data with result
 * const dataWithResult = getLastActionData(actions, type, true);
 * console.log(dataWithResult);
 * // Output example: { comment: 'Successfully sent Tale Me More', result: 'Success' }
 */
export function getLastActionData(actions = [], type, hasResult = false) {
  let result = "";
  const currentAction = actions.find((action) => type === action.action_type);

  if (!currentAction) return { comment: "", result: "" };

  if (hasResult) {
    result = currentAction.result_type;
  }

  return {
    comment: parseHtmlToString(currentAction.comment) || "No comment",
    ...(hasResult && { result }),
  };
}

/**
 * Retrieves the last identified action based on specific action types by datetime.
 *
 * @param {Array} actions - The list of action objects to search.
 * @returns {Object} - The last identified action object based on specific action types, or an empty object if no matching actions are found.
 * @property {string} action_type - The type of action.
 * @property {number} ats_action_id - The unique identifier of the action.
 *
 * @example
 * // Example usage with mocked data
 * const actions = [
 *   { action_type: 'CANDIDATE_NOT_YET_CONTACTED', ats_action_id: 1, datetime: '2023-01-01T12:00:00Z' },
 *   { action_type: 'MESSAGE_EMAIL', ats_action_id: 2, datetime: '2023-01-02T12:00:00Z' },
 *   { action_type: 'LINKEDIN_INVITATION', ats_action_id: 3, datetime: '2023-01-03T12:00:00Z' },
 * ];
 *
 * // Get the last identified action
 * const lastAction = getIdentifiedLastActionByDatetime(actions);
 * console.log(lastAction);
 * // Output example: { action_type: 'LINKEDIN_INVITATION', ats_action_id: 3, datetime: '2023-01-03T12:00:00Z' }
 */
export function getIdentifiedLastActionByDatetime(actions = []) {
  const types = [
    ACTION.CANDIDATE_NOT_YET_CONTACTED,
    ACTION.LONG_LIST,
    ACTION.MESSAGE_EMAIL,
    ACTION.LINKEDIN_INVITATION,
  ];

  const lowerCaseTypes = types.map((el) => el.toLowerCase());
  const currentActions = actions.filter((action) =>
    lowerCaseTypes.includes(`${action.action_type.toLowerCase()}`),
  );

  const lastAction =
    currentActions.length > 0
      ? currentActions.reduce((accumulator, current) => {
          return accumulator.ats_action_id > current.ats_action_id
            ? accumulator
            : current;
        })
      : [];

  return lastAction;
}

/**
 *
 * @param {Array<Object>} actions - The array of actions to filter.
 * @param {keyof ActionTypes} actionType - The type of action to filter by.
 *
 * @returns {Array<Object>} - The filtered array of actions matching the action type.
 *
 * @example
 * // Example usage:
 * const actions = [
 *   { id: 1, action_type: ACTION.NEGATIVE_ANSWER_SENT, ... },
 *   { id: 2, action_type: ACTION.LINCOLN_INTERVIEW, ... },
 *   { id: 3, action_type: ACTION.PHONE_CALL, ... },
 *   // Additional actions
 * ];
 * const filteredActions = filterActionByType(actions, ACTION.LINCOLN_INTERVIEW);
 * console.log(filteredActions);
 * // Output: [{ id: 2, action_type: "Lincoln Interview", ... }]
 *
 * @see ActionTypes
 */
export function filterActionByType(actions, actionType) {
  return actions.filter((action) => action.action_type === actionType);
}

/**
 * Generates a standardized object for creating or updating an action.
 *
 * @param {Object} params - The parameters for the action data.
 * @param {string} params.atsPersonId - The ID of the person in the ATS system.
 * @param {string} params.atsCompanyId - The ID of the company in the ATS system.
 * @param {string} params.atsAssignmentId - The ID of the assignment in the ATS system.
 * @param {string} params.actionType - The type of action to be performed (e.g., create, update).
 * @returns {Object} A standardized object containing action data.
 * @returns {string} return.action_type - The action type provided.
 * @returns {string} return.ats_person_id - The person ID provided.
 * @returns {string} return.ats_company_id - The company ID provided.
 * @returns {string} return.ats_assignment_id - The assignment ID provided.
 */
export function getCommonCreateOrUpdateActionData({
  atsPersonId,
  actionType,
  atsCompanyId,
  atsAssignmentId,
}) {
  return {
    ats_person_id: atsPersonId,
    ats_assignment_id: atsAssignmentId,
    ...(actionType && { action_type: actionType }),
    ...(atsCompanyId && { ats_company_id: atsCompanyId }),
  };
}
