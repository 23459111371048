import { Business } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { ASSIGNMENTS_DEFAULT_REDIRECT_URL } from "utils/constants/assignment/assignment";

import { DocumentIcon } from "../../Icons/DocumentIcon/DocumentIcon";
import { HomeIcon } from "../../Icons/HomeIcon/HomeIcon";
import { KPIIcon } from "../../Icons/KPIIcon/KPIIcon";
import { AssignmentIcon } from "../../Icons/AssignmentIcon/AssignmentIcon";
import { TalentIcon } from "../../Icons/TalentIcon/TalentIcon";
import DirectoryIcon from "../../Icons/DirectoryIcon/DirectoryIcon";
import { FollowUpIcon } from "../../Icons/FollowUpIcon/FollowUpIcon";

export const NavItems = [
  {
    id: 0,
    icon: <HomeIcon className="me-2" />,
    text: "Home",
    link: "/",
    isExternalLink: false,
  },
  {
    id: 2,
    icon: <AssignmentIcon className="me-2" />,
    text: "Assignments",
    link: ASSIGNMENTS_DEFAULT_REDIRECT_URL,
    isExternalLink: false,
  },
  {
    id: 3,
    icon: <Business sx={{ width: 25, height: 25 }} />,
    text: "Company",
    link: "/companies",
    isExternalLink: false,
  },
  {
    id: 4,
    icon: <TalentIcon className="me-2" />,
    text: "Talents",
    link: "/talent",
    isExternalLink: false,
  },
  // DO NOT REMOVE, TO BE USED LATER
  // {
  //   id: 5,
  //   icon: <InnovationBoxIcon className="me-2" />,
  //   text: "Innovation box",
  //   link: "/v1/#innovation-box",
  //   isExternalLink: true,
  // },
  // {
  //   id: 6,
  //   icon: <LinkiPediaIcon className="me-2" />,
  //   text: "Linckipedia",
  //   link: "https://wordpress.lincoln-hrgroup.com",
  //   isExternalLink: true,
  // },
  {
    id: 6,
    icon: <KPIIcon className="me-2" />,
    text: "KPI",
    link: "/kpi",
    isExternalLink: false,
  },
  {
    id: 7,
    icon: <FollowUpIcon className="me-2" />,
    text: "Follow Up",
    link: "/follow-up",
    isExternalLink: false,
  },
  {
    id: 8,
    icon: <DirectoryIcon className="me-2" />,
    text: "Directory",
    link: "/directory",
    isExternalLink: false,
  },
  // {
  //   id: 9,
  //   icon: <Groups sx={{ width: 25, height: 25 }} />,
  //   text: "Group",
  //   link: "/group",
  //   isExternalLink: false,
  // },
  {
    id: 10,
    icon: <DocumentIcon className="me-2" />,
    text: "Documents",
    link: "/document",
    isExternalLink: false,
  },
  {
    id: 11,
    icon: <AccountBalanceIcon />,
    text: "Invoicing",
    link: "/invoicing",
    isExternalLink: false,
  },
];
